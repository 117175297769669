<template>
    <layout :classes="classes" :routeName="routeName" :navigation="navigation" :sidebar="sidebar" :scope="scope">
        <div class="heading-container">
            <h1 class="title">{{$t(`payments.page.title`)}}</h1>
        </div>
    </layout>
</template>

<script>
import Layout from '../../components/Layout.vue';

export default {
    name: 'PaymentsSuccess',

    components: {
        Layout,
    },

    props: {
        navigation: {
            type: String,
            default: 'simple'
        },

        sidebar: {
            type: Boolean,
            default: false
        },

        scope: {
            type: Object,
            default() {
                return {};
            }
        }
    },

    data() {
        return {
            routeName: 'payments-success',
            classes: { dashboard: true, payments: true },
            loaded: false,

            add_payment: {
                modal: false
            }
        }
    },

    methods: {
        retrieve_query: function() {
            const self = this;
            const query_params = self.$route.query;

            console.log(query_params);
        }
    },

    mounted() {
        const self = this;

        self.retrieve_query();
    }
}
</script>

